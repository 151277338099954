<template>
  <v-container>
    <div class="d-flex flex-row flex-grow-1">
      <PageTitle
        text="Sales records for lubes"
        class="ft font-size-sm black--text"
      />
      <div>
        <v-text-field
          dense
          append-icon="search"
          filled
          v-model="search"
          outlined
          placeholder="Search branches ..."
          class="mt-2 ft font-size-sm black--text"
          hide-details
        />
      </div>
    </div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="ft font-weight-bold font-size-md mx-1">
        Total Lube Sales -
        <v-chip small label color="primary">
          {{
            events.reduce(
              (acc, item) =>
                (acc += isNaN(parseFloat(item.totalAmount))
                  ? 0
                  : parseFloat(item.totalAmount)),
              0
            ) | currencyFormat
          }}
        </v-chip>
      </div>
    </div>
    <v-row align="center">
      <v-col cols="12" sm="12">
        <v-chip-group
          light
          v-model="tag"
          class="box-shadow-light"
          show-arrows
          active-class="primary--text"
        >
          <SkeletonPreloader
            v-if="isBranchesLoading"
            :count="12"
            class="ml-2"
          />
          <v-chip
            v-else
            label
            color="white"
            class="ft box-shadow-light"
            v-for="(tag, index) in filteredTags"
            :value="tag.id"
            :key="index"
          >
            <span class="font-weight-bold text-uppercases">{{ tag.name }}</span>
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" lg="12" sm="12">
        <v-card class="pa-3 box-shadow-light ">
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn
                outlined
                class="mr-4 ft"
                :loading="isPageLoading"
                color="grey darken-2"
                @click="setToday"
              >
                Today
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>
                  arrow_back
                </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> arrow_forward </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar" class="ft font-size-md">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right class="ft">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    :loading="isPageLoading"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list class="ft">
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title class="ft font-size-sm text-uppercase"
                      >Day</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title class="ft font-size-sm text-uppercase"
                      >Week</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title class="ft font-size-sm text-uppercase"
                      >Month</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title class="ft font-size-sm text-uppercase"
                      >4 days</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="750">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              class="font-weight-bold ft "
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @moved="onCalendarChange"
              @change="updateRange"
            >
            </v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
              style="box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14),0 9px 46px 8px rgba(0,0,0,0.12),0 11px 15px -7px rgba(0,0,0,0.2)"
            >
              <v-card min-width="380px" flat>
                <v-toolbar flat dense color="rgba(238, 238, 238, 0.6)">
                  <v-toolbar-title>
                    <span class="ft black--text font-size-md font-weight-bold"
                      >Sales ({{ selectedEvent.start | fromNow }})</span
                    >
                  </v-toolbar-title>
                  <v-spacer />
                  <v-btn
                    color="black"
                    @click="selectedOpen = false"
                    icon
                    small
                    class="mr-2"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-card-text>
                  <v-alert
                    v-if="salesList.some(sale => !sale.isFinalized)"
                    type="warning"
                    dense
                  >
                    <span class=" ft font-weight-medium font-size-sm"
                      >Sales pending approval</span
                    >
                  </v-alert>
                  <div class="d-flex flex-column flex-grow-1 pb-3">
                    <p>
                      <v-chip
                        label
                        small
                        class="ft white--text font-weight-bold font-size-md mt-n1"
                        color="primary"
                        >Total Sales</v-chip
                      ><span
                        class="pl-3 ft pt-2 font-weight-bold font-size-md "
                        >{{ selectedEvent.totalAmount | currencyFormat }}</span
                      >
                      <v-chip
                        label
                        small
                        class="font ml-5 white--text font-weight-bold font-size-md mt-n1"
                        color="primary"
                        >Total Sold</v-chip
                      ><span
                        class="pl-3 ft pt-2 font-weight-bold font-size-md "
                        >{{ selectedEvent.totalSold }}</span
                      >
                    </p>
                  </div>
                  <v-data-table
                    :items="salesList"
                    :headers="headers"
                    :options.sync="options"
                    :server-items-length="paginate.total"
                    :loading="isListLoading"
                    class="ft font-weight-medium"
                    :items-per-page="paginate.limit"
                    :footer-props="{
                      itemsPerPageOptions: [2, 3, 5]
                    }"
                  >
                    <template #item.productId="{item}">
                      <span>{{ item.productId.name }}</span>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row></v-container
  >
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { Api } from "../../../api/Api";
import moment from "moment";
import { currencyFormat } from "../../../filters/currencyFormat";
export default {
  components: {
    SkeletonPreloader: () => import(`../../../components/SkeletonPreloader`),
    PageTitle: () => import(`../../../components/PageTitle`)
  },
  data: () => ({
    showSnackBar: "",
    isPageLoading: false,
    isListLoading: false,
    message: "",
    status: "",
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days"
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    salesList: [],
    options: {},
    isBranchesLoading: false,

    tag: null,
    tags: [],
    search: "",
    date: null,

    paginate: {
      total: 0,
      page: 1,
      limit: 2
    },
    headers: [
      {
        text: "Product",
        value: "productId"
      },
      {
        text: "Quantity Sold",
        value: "quantitySold"
      }
    ]
  }),
  computed: {
    filteredTags() {
      return this.tags.filter(branch => {
        return (
          branch.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        );
      });
    }
  },
  filters: {
    currencyFormat,
    fromNow(value) {
      return moment(value).fromNow();
    }
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  watch: {
    options: {
      handler(options) {
        if (this.selectedEvent) {
          this.salesListByDate({
            start: this.selectedEvent.start,
            page: options.page,
            limit: options.itemsPerPage
          });
        }
      },
      deep: true
    },
    tag(value) {
      this.getAllSalesByMonthForBranch({
        date: this.date ?? moment().format("YYYY-MM-DD"),
        branchId: value
      });
    }
  },

  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.salesListByDate({ start: event.start, ...this.paginate });
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      console.log(nativeEvent);

      nativeEvent.stopPropagation();
    },
    updateRange({ start }) {
      if (this.tag) {
        this.getAllSalesByMonthForBranch({
          branchId: this.tag,
          date: start.date
        });
      }
    },
    onCalendarChange({ date }) {
      this.date = date;
      if (this.tag) {
        this.getAllSalesByMonthForBranch({
          branchId: this.tag,
          date
        });
      }
    },
    async salesListByDate({ start, page, limit }) {
      try {
        this.isListLoading = true;
        let query = this.tag ? `&branchId=${this.tag}` : "";
        const response = await Api().get(
          `/sales/by/date/${start}?isPaginated=true&page=${page}&limit=${limit}${query}`
        );
        if (response) {
          this.isListLoading = false;
          this.salesList = response.data.paginateObj.docs;
          const { page, total, limit } = response.data.paginateObj;
          this.paginate = { page, total, limit: parseInt(limit) };
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = "Unable to fetch sales at this time";
        this.success = true;
        this.isListLoading = false;
      }
    },
    async getAllSalesByMonthForBranch({ branchId, date }) {
      try {
        this.isPageLoading = true;
        const response = await Api().get(`/sales/by/month/${branchId}/${date}`);
        if (response) {
          this.events = response.data.map(event => {
            return {
              name: `Lubes - ${currencyFormat(event.total)}`,
              start: moment(event.date)
                .utc()
                .format("YYYY-MM-DD"),
              end: moment(event.date)
                .utc()
                .format("YYYY-MM-DD"),
              totalSold: event.totalSold,
              totalAmount: event.total,
              color: "accent"
            };
          });
          this.isPageLoading = false;
        }
      } catch (e) {
        this.showSnackBar = true;
        this.isPageLoading = false;
        (this.message = e.response.data.message ?? "Unable to fetch sales"),
          (this.status = "red");
      }
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    async getBranches() {
      try {
        this.isBranchesLoading = true;
        const response = await Api().get(`/branches`);
        if (response) {
          this.isBranchesLoading = false;
          if (response.data.length > 0) this.tag = response.data[0]?.id;
          this.tags = response.data.map(branch => {
            return {
              name: branch.name,
              id: branch.id,
              slug: branch.slug
            };
          });
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = "Unable to fetch sales at this time";
        this.success = true;
        this.isBranchesLoading = true;
      }
    }
  },
  created() {
    this.getBranches();
  }
};
</script>

<style scoped></style>
